import React from 'react';
import { Link } from 'gatsby';
import { SEO } from '../components/SEO';
import MainLayout from '../layouts/main';
import { Button, Card, IconButton, Typography } from '@material-ui/core';
import PageHead from '../components/PageHead';
import styled from '../styled';
import {
  Award,
  ChevronRight,
  CloudLightning,
  Heart,
  Linkedin,
  Percent,
  Scissors,
  Umbrella,
  Wind
} from 'react-feather';
import { COLORS } from '../themes/color';
import { ContactForm } from '../components/ContactForm';

const ValuesWrapper = styled('div')`
  max-width: 900px;
  margin: 0 auto;
  padding: ${(p) => p.theme.spacing(2)}px;

  @media (max-width: 800px) {
    padding: ${(p) => p.theme.spacing(2)}px;
  }
`;

const ValuesGrid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: ${(p) => p.theme.spacing(8)}px;
  grid-row-gap: ${(p) => p.theme.spacing(8)}px;
  justify-content: center;

  p {
    color: #4f4f4f;
    font-size: 16px;
    line-height: 27px;
  }

  a {
    border-bottom: 1px dashed;
  }

  h3 {
    font-weight: normal;
    font-size: 20px;
    line-height: 36px;
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;

export const ValuesIcon = styled('div')`
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.TEXT};
  background-color: ${COLORS.PAPER_DARK};
`;

const PressItem = ({
  img,
  href,
  heading,
  subheading
}: {
  img: string;
  href: string;
  heading: string;
  subheading: string;
}) => {
  return (
    <div>
      <a href={href} target="_blank" rel="nofollow">
        <img src={img} alt={heading} />
      </a>
      <Typography
        variant="h6"
        component="h3"
        style={{ fontWeight: 'bold' }}
        gutterBottom
      >
        {heading}
      </Typography>
      <Typography variant="body2" component="p" color="textSecondary">
        {subheading}
      </Typography>
    </div>
  );
};

const PressGrid = styled('div')`
  display: grid;
  justify-content: center;
  text-align: center;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 900px;
  margin: 0 auto 100px;
  grid-column-gap: ${(p) => p.theme.spacing(3)}px;
  grid-row-gap: ${(p) => p.theme.spacing(8)}px;

  img {
    display: inline-block;
    transform: scale(1);
    max-width: 100%;
    margin: 0 auto ${(p) => p.theme.spacing(2)}px;
    border-radius: ${(p) => p.theme.shape.borderRadius * 3}px;
    transition: all 0.2s ease-in-out;
    border: 1px solid #eee;
    box-shadow: 0px 1px 1px -1px rgba(0, 0, 0, 0.1),
      2px 5px 4px -1px rgba(0, 0, 0, 0.01);

    &:hover {
      transform: scale(1.05);
      box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.05),
        2px 5px 9px 2px rgba(0, 0, 0, 0.01);
    }
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: ${(p) => p.theme.spacing(4)}px;
  }
`;

const Grid = styled('div')`
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: left;
  grid-template-columns: 2fr 3fr;
  grid-column-gap: ${(p) => p.theme.spacing(4)}px;
  max-width: 900px;
  margin: 0 auto;

  img {
    display: inline-block;
    margin: 0 auto;
  }

  @media (max-width: 800px) {
    padding: 0 ${(p) => p.theme.spacing(2)}px;
    grid-template-columns: 1fr;
    grid-row-gap: ${(p) => p.theme.spacing(4)}px;
  }
`;

const FeaturedImage = styled('img')`
  border-radius: ${(p) => p.theme.shape.borderRadius * 3}px;
  max-width: 100%;
  display: block;
  margin-bottom: ${(p) => p.theme.spacing(2)}px;
`;

const TeamWrapper = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 900px;
  margin: 0 auto;
`;

export default () => (
  <MainLayout>
    <>
      <SEO
        title="About Affilimate"
        description="Learn about Affilimate's mission and team"
        pathname="/about/"
      />
      <PageHead>
        <Typography
          variant="h3"
          gutterBottom
          component="h1"
          style={{
            maxWidth: '600px',
            margin: '0 auto 36px',
            fontWeight: 900
          }}
        >
          We build tools to empower publishers
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          style={{ maxWidth: '600px', margin: '24px auto 12px' }}
        >
          Affilimate gives media companies and creators visibility and control
          over their affiliate business through cutting-edge tools and the
          industry's most granular data.
        </Typography>
      </PageHead>
      <Typography
        variant="h4"
        gutterBottom
        component="h2"
        style={{
          maxWidth: '600px',
          margin: '100px auto 12px',
          fontWeight: 900,
          textAlign: 'center'
        }}
      >
        Meet the founders
      </Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        style={{
          textAlign: 'center',
          maxWidth: '550px',
          margin: '12px auto 48px'
        }}
      >
        Here's a look at our story so far
      </Typography>
      <Grid>
        <FeaturedImage src="/images/photos/founders-square.jpg" />
        <div>
          <Typography variant="body1" component="p" color="textSecondary">
            <strong style={{ color: 'black' }}>Lynda Mann</strong> previously
            led commerce at Wirecutter, pre- and post-acquisition to the New
            York Times. She then served as the SVP of Commerce at Digital Trends
            for four years. Lynda leads operations and growth at Affilimate.
          </Typography>
          <IconButton
            href="https://www.linkedin.com/in/lynda-mann/"
            style={{ padding: 0, marginTop: '12px' }}
          >
            <Linkedin size={16} />{' '}
          </IconButton>
          <br />
          <br />
          <Typography variant="body1" component="p" color="textSecondary">
            <strong style={{ color: 'black' }}>Monica Lent</strong> previously
            led web engineering at SumUp, one of Europe's fastest growing
            fintech startups. In parallel, she ran a successful
            affiliate-monetized travel website. Monica leads product and
            engineering at Affilimate.
          </Typography>
          <IconButton
            href="https://www.linkedin.com/in/monicalent/"
            style={{ padding: 0, marginTop: '12px' }}
          >
            <Linkedin size={16} />{' '}
          </IconButton>
        </div>
      </Grid>
      <Typography
        variant="h4"
        gutterBottom
        component="h2"
        style={{
          maxWidth: '600px',
          margin: '100px auto 12px',
          fontWeight: 900,
          textAlign: 'center'
        }}
      >
        Our values
      </Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        style={{
          textAlign: 'center',
          maxWidth: '550px',
          margin: '12px auto 48px'
        }}
      >
        Our mission is to help publishers build sustainable media businesses
        through commerce. We do that with:
      </Typography>
      <ValuesWrapper>
        <ValuesGrid>
          <div>
            <ValuesIcon>
              <Percent size={20} />
            </ValuesIcon>
            <h3>Pragmatism</h3>
            <p>
              We believe that speed and quality are not mutually exclusive.
              Being focused and intentional helps deliver value sooner, without
              cutting corners.
            </p>
          </div>
          <div>
            <ValuesIcon>
              <Scissors size={20} />
            </ValuesIcon>
            <h3>Simplicity</h3>
            <p>
              Design matters. We craft elegant solutions to complicated
              problems. This democratizes who can get value from our product and
              how quickly.
            </p>
          </div>
          <div>
            <ValuesIcon>
              <Wind size={20} />
            </ValuesIcon>
            <h3>Drive</h3>
            <p>
              What works today won't necessarily work tomorrow. We are
              ambitious, take initiative, and are always working on our next
              major innovation.
            </p>
          </div>
          <div>
            <ValuesIcon>
              <Award size={20} />
            </ValuesIcon>
            <h3>Quality</h3>
            <p>
              In the long run, quality wins. And we're here to build lasting
              relationships with our customers, partners, and teammates. We are
              proud of what we're building.
            </p>
          </div>
          <div>
            <ValuesIcon>
              <Heart size={20} />
            </ValuesIcon>
            <h3>Integrity</h3>
            <p>
              We're in it to win, but not at any cost. We deliver on our
              promises and communicate with honesty. We take responsibility and
              ownership for our work.
            </p>
          </div>
          <div>
            <ValuesIcon>
              <Umbrella size={20} />
            </ValuesIcon>
            <h3>Partnership</h3>
            <p>
              We are collaborative, helpful, and supportive. We're here to help
              publishers succeed and the industry to grow. We're stronger
              together.
            </p>
          </div>
        </ValuesGrid>
      </ValuesWrapper>
      <Typography
        variant="h4"
        gutterBottom
        component="h2"
        style={{
          maxWidth: '600px',
          margin: '100px auto 12px',
          fontWeight: 900,
          textAlign: 'center'
        }}
      >
        What to find out how we can work together?
      </Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        style={{
          textAlign: 'center',
          maxWidth: '550px',
          margin: '12px auto 48px'
        }}
      >
        Get in touch with us via our contact form below:
      </Typography>
      <Card
        style={{ maxWidth: '500px', margin: '0 auto 100px', padding: '24px' }}
      >
        <ContactForm />
      </Card>
    </>
  </MainLayout>
);
